import React from "react";
import {
  HeroImage,
  HeroContent,
  MainButtons,
  MainDescription,
  MainDetails,
  MainHero,
  MainTitlte,
} from "../styles/Hero.styled";
import { StyledButton } from "../styles/Button.styled";
import {
  // BsArrowUpRightCircleFill as ArrowIcon,
  BsFillPlayCircleFill as PlayIcon,
} from "react-icons/bs";
import {isMobile} from 'react-device-detect';

import roselogo from "../../assets/roselogo.png"

const Hero = () => {
  var gclink="https://gymcatch.com/app/provider/5622/events";
  if(isMobile){
    // gclink="gymcatch.app.link://";
  }
  return (
    <MainHero>
      <MainDetails>
        <MainTitlte>
          <span style={{ color: "lightred","opacity":".4" }}>#</span>RoseGarden.Yoga
          {/* Make yourself <MainTitleLine>stronger </MainTitleLine> <br />
          <MainTitleTextGradient>than your excuses.</MainTitleTextGradient> */}
        </MainTitlte>

        <HeroContent>
          <div className="myhero">
          <a href={gclink} rel="noreferrer" target="_blank" style={{"textDecoration": "none"}}>
            <HeroImage src={roselogo} alt="main image"  />
          </a>
          </div >
          <MainButtons>
            <a href={gclink} rel="noreferrer" target="_blank" style={{"textDecoration": "none"}}>

            <StyledButton primary={true} >
              Class Schedule <PlayIcon />
            </StyledButton>
            </a>
          </MainButtons>
          <MainDescription>
          A consistent yoga practice may be the best investment in your long term health. 
          We provide the foundations for transformation through honest Ashtanga Yoga principals. In 5 sessions, you will feel the difference. After 10 sessions, you will see the difference. 
          </MainDescription>
        </HeroContent>
      </MainDetails>

    </MainHero>
  );
};

export default Hero;
