import React from "react";
import styled from "styled-components";

import {
  HeroImage,
  // HeroContent,
  // MainButtons,
  // MainDescription,
  MainDetails,
  MainHero,
  MainTitlte,
} from "../styles/Hero.styled";
import { StyledButtonSimplex } from "../styles/Button.styled";
import {
  // BsArrowUpRightCircleFill as ArrowIcon,
  BsFillPlayCircleFill as PlayIcon,
} from "react-icons/bs";
import { isMobile } from 'react-device-detect';

import roselogo from "../../assets/roselogo.png"

const HeroSimplex = (props) => {
  var gclink = "https://gymcatch.com/app/provider/5622/events";
  if (isMobile) {
    // gclink="gymcatch.app.link://";
  }
  return (
    <MainHero>
      <MainDetails>
        <MainTitlte>
          <span style={{ color: "lightred", "opacity": ".4" }}>#</span>RoseGarden.Yoga
          {/* Make yourself <MainTitleLine>stronger </MainTitleLine> <br />
          <MainTitleTextGradient>than your excuses.</MainTitleTextGradient> */}

          <div className="myhero">
            <div style={{ "marginTop": "10px" }}>
              <a href={gclink} rel="noreferrer" target="_blank" style={{ "textDecoration": "none" }}>
                <HeroImage src={roselogo} alt="main image" />
              </a>
            </div>
            <div>
              <a href={gclink} rel="noreferrer" target="_blank" style={{ "textDecoration": "none" }}>
                <StyledButtonSimplex primary={true} >
                  Class Schedule <PlayIcon />
                </StyledButtonSimplex>
              </a>
                <Message>
                {props.message} 
                </Message>
                <TableMsg> 
                {props.tablemsg}
                </TableMsg>
            </div>
          </div >
        </MainTitlte>

      </MainDetails>
      <div style={{ "margin": "auto", "textAlign": "center", "color": "#3f3f1f", "fontStyle": "italic" }}>
        <a href="/about">More information</a>
      </div>

    </MainHero>
  );
};

//Join us for Deep Stretch online zoom classes Tuesdays and Thursdays @ 6pm
// 
//              We're rained out :(. Sunday 2/4 10:00am Moon Sequence is Online via <a href="/onlineclass">Zoom.</a>

const TableMsg = styled.table`
margin: auto;
padding: 0.85rem 1.9rem;
  line-height: 1.5;
  font-size: .55rem;
  // max-width: 100px;
  text-align: center;
  justify-content: center;
  color: #afafaf;
`;

const Message = styled.div`
padding: 0.85rem 1.9rem;
  line-height: 1.5;
  font-size: .85rem;
  // max-width: 100px;
  text-align: center;
  justify-content: center;

`;
export default HeroSimplex;
