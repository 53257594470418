import React from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import { GlobalStyles } from "./components/styles/Global";
import { ThemeProvider } from "styled-components";
import { theme } from "./components/styles/Theme";
import HeroSimplex from "./components/Hero/Hero_simplex";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import Map from "./components/Map/Map";
import RGButton from "./components/RGButton/RGButton";
import './general.css'

/////////////////////////////////////////////////////////////////////////////////////////////////
// Link to online zoom class
var ZOOM = "NO MORE ZOOM"
// ZOOM = "CANCELLED"
// ZOOM = "At the Rose Garden - no zoom"
var CLASSDATE = "Thursday 4/4/2024"
var CLASSTIME = "6:00pm"
var MESSAGE = "UPDATE: "+CLASSDATE+" @ " + CLASSTIME + " - **SESSION CANCELLED** - No Zoom class."
MESSAGE = "UPDATE: Today's 4/4/24 park class is cancelled.  Zoom class instead."
MESSAGE = "NO MORE ZOOM"
// var MESSAGE = "UPDATE: Tuesday 2/13 6pm Deep Stretch online zoom class CANCELLED." 
MESSAGE = "Week of May 13: Deep Stretch Wednesday @ 6p - No class on Tuesday and Thursday"
// MESSAGE = "Mother's Day Yoga - 5/12 Sunday @ 10a for Moon Sequence"
MESSAGE = "Join us at the Rose Garden Tuesdays & Thursdays @ 6p for Deep Stretch and Sundays @ 10a for Moon Sequence"
// MESSAGE = "Update: Thursday June 27 @ 6p Deep Stretch is CANCELLED - See you Sunday @ 10a for Moon Sequence"
// MESSAGE = "Tuesday May 28 6p and Thursday May 30 6p classes are *cancelled* for SJHS graduation.  See you on Sunday!" 
var TABLEMSG =
<table>
  <tr><td>Moon Sequence</td><td>Sundays @ 10a -> Rose Garden</td></tr>
  <tr><td>Deep Stretch</td><td>Tuesdays @ 6p-> Rose Garden</td></tr>
  <tr><td>Deep Stretch</td><td>Thursdays @ 6p-> Rose Garden</td></tr>
</table>
/////////////////////////////////////////////////////////////////////////////////////////////////

// Landing page
function Landing() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="container">
        <HeroSimplex message={MESSAGE} tablemsg={TABLEMSG}/>
      </div>
    </ThemeProvider>)
}

// More information page
function Detail() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="container">
        <Hero />
        <About />
        <RGButton />
        <Map />
        <Footer />
      </div>
    </ThemeProvider>)
}
// Error redirects to landing
function ErrorBoundary() {
  return <Landing />;
}

function Venmo() {
  window.location.replace(ZOOM)
}

function ZoomPage() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="container" style={{ marginTop: 100, textAlign: "center" }}>
        Connect to {CLASSDATE} @ {CLASSTIME} online zoom class:
      </div>
      <div className="container" style={{ marginTop: 20, textAlign: "center" }}>
        <a href={ZOOM} target="_blank" rel="noreferrer" >{ZOOM}</a>
      </div>      
    </ThemeProvider>)


}
// Router definition
const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorBoundary />
  },
  {
    path: "/about",
    element: <Detail />,
    errorElement: <ErrorBoundary />
  },
  {
    path: "/onlineclass",
    element: <ZoomPage />
  },
  {
    path: "/sunday",
    element: <ZoomPage />
  },
  {
    path: "/zoom",
    element: <Venmo />
  }
]);

// Application
function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
