import React from "react";

import { StyledButton } from "../styles/Button.styled";
import {
  // BsArrowUpRightCircleFill as ArrowIcon,
  BsFillPlayCircleFill as PlayIcon,
} from "react-icons/bs";
import {isMobile} from 'react-device-detect';


const RGButton = () => {
  var gclink="https://gymcatch.com/app/provider/5622/events";
  if(isMobile){
    // gclink="gymcatch.app.link://";
  }
  return (
            <div className="rgbutton">
                <a href={gclink} target="_blank" rel="noreferrer" style={{"textDecoration": "none"}}>
                <StyledButton primary={true} >
                    Class Schedule <PlayIcon />
                </StyledButton>
                </a>
          </div>

  );
};

export default RGButton;
